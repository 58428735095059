import React, { Component } from 'react';

class MyComponent extends Component {
  componentDidMount() {
    // Replace this URL with the endpoint you want to fetch data from
    const apiUrl = 'http://18.195.216.214/';

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        // Process the JSON data here
        console.log(data);
      })
      .catch((error) => {
        // Handle errors here
        console.error('There was a problem with the fetch operation:', error);
      });
  }

  render() {
    return (
      <div>
        {/* Your component JSX */}
      </div>
    );
  }
}

export default MyComponent;